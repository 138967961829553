import * as PATHS from './constants';

// CENTER

export const routeCenterMain = (
  name = PATHS.PATH_CENTER_MAIN,
  params = {},
  path = PATHS.PATH_CENTER_MAIN
) => ({
  path,
  name,
  component: () => import('../views/center/MainView.vue'),
  ...params
});

export const routeCenterVideoConference = (
  name = PATHS.PATH_CENTER_VIDEO_CONFERENCE,
  params = {},
  path = PATHS.PATH_CENTER_VIDEO_CONFERENCE
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/VideoConferenceView.vue'),
  ...params
});

export const routeCenterPosts = (
  name = PATHS.PATH_CENTER_POSTS,
  params = {},
  path = PATHS.PATH_CENTER_POSTS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/PostsView.vue'),
  ...params
});

export const routeCenterPostsTag = (
  name = PATHS.PATH_CENTER_POSTS_TAG,
  params = {},
  path = PATHS.PATH_CENTER_POSTS_TAG
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/PostsView.vue'),
  ...params
});

export const routeCenterPostsCategory = (
  name = PATHS.PATH_CENTER_POSTS_CATEGORY,
  params = {},
  path = PATHS.PATH_CENTER_POSTS_CATEGORY
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/CategoryView.vue'),
  ...params
});

export const routeCenterLessons = (
  name = PATHS.PATH_CENTER_LESSONS,
  params = {},
  path = PATHS.PATH_CENTER_LESSONS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/LessonsView.vue'),
  ...params
});

export const routeCenterLessonsTheme = (
  name = PATHS.PATH_CENTER_LESSONS_THEME,
  params = {},
  path = PATHS.PATH_CENTER_LESSONS_THEME
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/LessonsByThemeView.vue'),
  ...params
});

export const routeCenterLessonsCategory = (
  name = PATHS.PATH_CENTER_LESSONS_CATEGORY,
  params = {},
  path = PATHS.PATH_CENTER_LESSONS_CATEGORY
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/LessonsView.vue'),
  ...params
});

export const routeCenterVideo = (
  name = PATHS.PATH_CENTER_VIDEO,
  params = {},
  path = PATHS.PATH_CENTER_VIDEO
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/VideoView.vue'),
  ...params
});

export const routeStudentFiles = (
  name = PATHS.PATH_STUDENT_FILES,
  params = {},
  path = PATHS.PATH_STUDENT_FILES
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/StudentFilesView.vue'),
  ...params
});

export const routeCenterOrganisations = (
  name = PATHS.PATH_CENTER_ORGANISATIONS,
  params = {},
  path = PATHS.PATH_CENTER_ORGANISATIONS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/OrganisationsView.vue'),
  ...params
});

export const routeCenterOrganisationsCategory = (
  name = PATHS.PATH_CENTER_ORGANISATIONS_COMPANY,
  params = {},
  path = PATHS.PATH_CENTER_ORGANISATIONS_COMPANY
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/OrganisationsView.vue'),
  ...params
});

export const routeCenterExperts = (
  name = PATHS.PATH_CENTER_EXPERTS,
  params = {},
  path = PATHS.PATH_CENTER_EXPERTS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/ExpertsView.vue'),
  ...params
});

export const routeCenterStock = (
  name = PATHS.PATH_CENTER_STOCK,
  params = {},
  path = PATHS.PATH_CENTER_STOCK
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/StockView.vue'),
  ...params
});
export const routeCenterQuestion = (
  name = PATHS.PATH_CENTER_QUESTION,
  params = {},
  path = PATHS.PATH_CENTER_QUESTION
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/QuestionView.vue'),
  ...params
});
export const routeCenterQuestions = (
  name = PATHS.PATH_CENTER_QUESTIONS,
  params = {},
  path = PATHS.PATH_CENTER_QUESTIONS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/QuestionsView.vue'),
  ...params
});
export const routeCenterQuestionsTheme = (
  name = PATHS.PATH_CENTER_QUESTIONS_THEME,
  params = {},
  path = PATHS.PATH_CENTER_QUESTIONS_THEME
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/ForumByThemeView.vue'),
  ...params
});
export const routeCenterQuestionsCategory = (
  name = PATHS.PATH_CENTER_QUESTIONS_CATEGORY,
  params = {},
  path = PATHS.PATH_CENTER_QUESTIONS_CATEGORY
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/QuestionsView.vue'),
  ...params
});

export const routeCenterProfile = (
  name = PATHS.PATH_CENTER_PROFILE,
  params = {},
  path = PATHS.PATH_CENTER_PROFILE
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/ProfileView.vue'),
  ...params
});

export const routeCenterBanner = (
  name = PATHS.PATH_CENTER_BANNER,
  params = {},
  path = PATHS.PATH_CENTER_BANNER
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/BannerView.vue'),
  ...params
});

export const routeCenterTheme = (
  name = PATHS.PATH_CENTER_THEME,
  params = {},
  path = PATHS.PATH_CENTER_THEME
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/ThemeView.vue'),
  ...params
});

//

export const routeMembership = (
  name = PATHS.PATH_MEMBERSHIP,
  params = {},
  path = PATHS.PATH_MEMBERSHIP
) => ({
  path,
  name,
  component: () => import('../views/membership/MembershipView.vue'),
  ...params
});

export const routeServices = (
  name = PATHS.PATH_SERVICES,
  params = {},
  path = PATHS.PATH_SERVICES
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/ServicesView.vue'),
  ...params
});

export const routeDigitalGoods = (
  name = PATHS.PATH_DIGITAL_GOODS,
  params = {},
  path = PATHS.PATH_DIGITAL_GOODS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/DigitalGoodsView.vue'),
  ...params
});

export const routeService = (
  name = PATHS.PATH_SERVICE,
  params = {},
  path = PATHS.PATH_SERVICE
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/ServiceView.vue'),
  ...params
});

export const routeSchoolBonuses = (
  name = PATHS.PATH_SCHOOL_BONUSES,
  params = {},
  path = PATHS.PATH_SCHOOL_BONUSES
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/ListBonusesBySchoolView.vue'),
  ...params
});

export const routeStudents = (
  name = PATHS.PATH_STUDENTS,
  params = {},
  path = PATHS.PATH_STUDENTS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/StudentsView.vue'),
  ...params
});

export const routeStudent = (
  name = PATHS.PATH_STUDENT,
  params = {},
  path = PATHS.PATH_STUDENT
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/StudentView.vue'),
  ...params
});

export const routeTeachers = (
  name = PATHS.PATH_TEACHERS,
  params = {},
  path = PATHS.PATH_TEACHERS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/TeachersView.vue'),
  ...params
});

export const routeTeacher = (
  name = PATHS.PATH_TEACHER,
  params = {},
  path = PATHS.PATH_TEACHER
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/TeacherView.vue'),
  ...params
});

export const routeAdministrators = (
  name = PATHS.PATH_ADMINISTRATORS,
  params = {},
  path = PATHS.PATH_ADMINISTRATORS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/AdministratorsView.vue'),
  ...params
});

export const routeFinance = (
  name = PATHS.PATH_FINANCE,
  params = {},
  path = PATHS.PATH_FINANCE
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/FinancesView.vue'),
  ...params
});

export const routeRequests = (
  name = PATHS.PATH_REQUESTS,
  params = {},
  path = PATHS.PATH_REQUESTS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/RequestsView.vue'),
  ...params
});

export const routeWorkTimes = (
  name = PATHS.PATH_WORK_TIMES,
  params = {},
  path = PATHS.PATH_WORK_TIMES
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/WorkTimesView.vue'),
  ...params
});

export const routeSchedule = (
  name = PATHS.PATH_SCHEDULE,
  params = {},
  path = PATHS.PATH_SCHEDULE
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/ScheduleView.vue'),
  ...params
});

export const routeTimetable = (
  name = PATHS.PATH_TIMETABLE,
  params = {},
  path = PATHS.PATH_TIMETABLE
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/TimetableView.vue'),
  ...params
});

export const routeSignIn = (
  name = PATHS.PATH_SIGN_IN_AUTH,
  params = {},
  path = PATHS.PATH_SIGN_IN_AUTH
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/SignInView.vue'),
  ...params
});

export const routeNotifications = (
  name = PATHS.PATH_NOTIFICATIONS,
  params = {},
  path = PATHS.PATH_NOTIFICATIONS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/NotificationsView.vue'),
  ...params
});

export const routeSchool = (
  name = PATHS.PATH_SCHOOL,
  params = {},
  path = PATHS.PATH_SCHOOL
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/SchoolView.vue'),
  ...params
});

export const routeCenterCompany = (
  name = PATHS.PATH_COMPANY,
  params = {},
  path = PATHS.PATH_COMPANY
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/CompanyView.vue'),
  ...params
});

export const routeSchoolPublic = (
  name = PATHS.PATH_SCHOOLS,
  params = {},
  path = PATHS.PATH_SCHOOLS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/SchoolsView.vue'),
  ...params
});

export const routeProfileVideos = (
  name = PATHS.PATH_PROFILE_VIDEOS,
  params = {},
  path = PATHS.PATH_PROFILE_VIDEOS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/videos/ProfileView.vue'),
  ...params
});

export const routeCenterMyCompanies = (
  name = PATHS.PATH_CENTER_MY_COMPANIES,
  params = {},
  path = PATHS.PATH_CENTER_MY_COMPANIES
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/MyCompaniesView.vue'),
  ...params
});

export const routeWebCenterMyCompanies = (
  name = PATHS.PATH_WEB_CENTER_MY_COMPANIES,
  params = {},
  path = PATHS.PATH_WEB_CENTER_MY_COMPANIES
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/WebMyCompaniesView.vue'),
  ...params
});

export const routeWebCompanyTimetable = (
  name = PATHS.PATH_WEB_CENTER_COMPANY_TIMETABLE,
  params = {},
  path = PATHS.PATH_WEB_CENTER_COMPANY_TIMETABLE
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/WebCompanyTimetableView.vue'),
  ...params
});

export const routeWebCompanyRecord = (
  name = PATHS.PATH_WEB_CENTER_COMPANY_RECORD,
  params = {},
  path = PATHS.PATH_WEB_CENTER_COMPANY_RECORD
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/WebCompanyRecordView.vue'),
  ...params
});

export const routeWebCompanyServices = (
  name = PATHS.PATH_WEB_CENTER_COMPANY_SERVICES,
  params = {},
  path = PATHS.PATH_WEB_CENTER_COMPANY_SERVICES
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/WebCompanyServicesView.vue'),
  ...params
});

export const routeCenterMyCalendar = (
  name = PATHS.PATH_MY_CALENDAR,
  params = {},
  path = PATHS.PATH_MY_CALENDAR
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/MyCalendarView.vue'),
  ...params
});

export const routeMembershipProfile = (
  name = PATHS.PATH_MEMBERSHIP_PROFILE,
  params = {},
  path = PATHS.PATH_MEMBERSHIP_PROFILE
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/ProfileView.vue'),
  ...params
});

export const routeProfileEdit = (
  name = PATHS.PATH_PROFILE_EDIT,
  params = {},
  path = PATHS.PATH_PROFILE_EDIT
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/ProfileEditView.vue'),
  ...params
});

export const routeStockByCompany = (
  name = PATHS.PATH_STOCK_BY_COMPANY,
  params = {},
  path = PATHS.PATH_STOCK_BY_COMPANY
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/StockByCompanyView.vue'),
  ...params
});

export const routeVideoconferenceByCompany = (
  name = PATHS.PATH_VIDEOCONFERENCE_BY_COMPANY,
  params = {},
  path = PATHS.PATH_VIDEOCONFERENCE_BY_COMPANY
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/VideoconferenceByCompanyView.vue'),
  ...params
});

export const routeTickets = (
  name = PATHS.PATH_TICKETS,
  params = {},
  path = PATHS.PATH_TICKETS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/TicketsView.vue'),
  ...params
});

export const routeServicesGroups = (
  name = PATHS.PATH_SERVICES_GROUPS,
  params = {},
  path = PATHS.PATH_SERVICES_GROUPS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/ServicesGroupsView.vue'),
  ...params
});

export const routeStudentsGroups = (
  name = PATHS.PATH_STUDENTS_GROUPS,
  params = {},
  path = PATHS.PATH_STUDENTS_GROUPS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/StudentsGroupsView.vue'),
  ...params
});

export const routeVisitsStudents = (
  name = PATHS.PATH_VISITS_STUDENTS,
  params = {},
  path = PATHS.PATH_VISITS_STUDENTS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/VisitsStudentsView.vue'),
  ...params
});

export const routeTicketsItems = (
  name = PATHS.PATH_TICKETS_ITEMS,
  params = {},
  path = PATHS.PATH_TICKETS_ITEMS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/TicketsItemsView.vue'),
  ...params
});

export const routeForms = (
  name = PATHS.PATH_FORMS,
  params = {},
  path = PATHS.PATH_FORMS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/FormsView.vue'),
  ...params
});

export const routeFormQuestions = (
  name = PATHS.PATH_FORM_QUESTIONS,
  params = {},
  path = PATHS.PATH_FORM_QUESTIONS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/FormQuestionsView.vue'),
  ...params
});

export const routeFormAnswers = (
  name = PATHS.PATH_FORM_ANSWERS,
  params = {},
  path = PATHS.PATH_FORM_ANSWERS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/FormAnswersView.vue'),
  ...params
});

export const routeCenterChat = (
  name = PATHS.PATH_CENTER_CHAT,
  params = {},
  path = PATHS.PATH_CENTER_CHAT
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/ChatView.vue'),
  ...params
});

export const routeFormsByClient = (
  name = PATHS.PATH_FORMS_BY_CLIENTS,
  params = {},
  path = PATHS.PATH_FORMS_BY_CLIENTS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/FormsByClientView.vue'),
  ...params
});

export const routeFilesByClient = (
  name = PATHS.PATH_FILES_BY_CLIENTS,
  params = {},
  path = PATHS.PATH_FILES_BY_CLIENTS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/membership/FilesByClientView.vue'),
  ...params
});
export const routeFormsAnswersByClient = (
  name = PATHS.PATH_FORMS_ANSWERS_BY_CLIENTS,
  params = {},
  path = PATHS.PATH_FORMS_ANSWERS_BY_CLIENTS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/FormsAnswersByClientView.vue'),
  ...params
});

export const routeFormsQuestionsByClient = (
  name = PATHS.PATH_FORMS_QUESTIONS_BY_CLIENTS,
  params = {},
  path = PATHS.PATH_FORMS_QUESTIONS_BY_CLIENTS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/FormsQuestionsByClientView.vue'),
  ...params
});

export const routeUserByArticle = (
  name = PATHS.PATH_USER,
  params = {},
  path = PATHS.PATH_USER
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/UserView.vue'),
  ...params
});

export const routePostByArticle = (
  name = PATHS.PATH_POST,
  params = {},
  path = PATHS.PATH_POST
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/ArticleView.vue'),
  ...params
});

export const routeBalance = (
  name = PATHS.PATH_BALANCE,
  params = {},
  path = PATHS.PATH_BALANCE
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/BalanceView.vue'),
  ...params
});

export const routeBalancePlatform = (
  name = PATHS.PATH_BALANCE,
  params = {},
  path = PATHS.PATH_BALANCE
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/BalancePlatformView.vue'),
  ...params
});

export const routeAuthors = (
  name = PATHS.PATH_AUTHORS,
  params = {},
  path = PATHS.PATH_AUTHORS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/AuthorsView.vue'),
  ...params
});

export const routePostsAndVideos = (
  name = PATHS.PATH_ARTICLES,
  params = {},
  path = PATHS.PATH_ARTICLES
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/PostsAndVideosView.vue'),
  ...params
});

export const routeCenterVideos = (
  name = PATHS.PATH_CENTER_VIDEOS,
  params = {},
  path = PATHS.PATH_CENTER_VIDEOS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/VideosView.vue'),
  ...params
});

export const routeMainVideos = (
  name = PATHS.PATH_MAIN_VIDEOS,
  params = {},
  path = PATHS.PATH_MAIN_VIDEOS
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/videos/VideosView.vue'),
  ...params
});

export const routeUserByPlatform = (
  name = PATHS.PATH_USER,
  params = {},
  path = PATHS.PATH_USER
) => ({
  path,
  name,
  props: true,
  component: () => import('../components/videos/UserView.vue'),
  ...params
});

export const routePostModerationByPlatform = (
  name = PATHS.PATH_VIDEO_MODERATOR,
  params = {},
  path = PATHS.PATH_VIDEO_MODERATOR
) => ({
  path,
  name,
  props: true,
  component: () => import('../views/center/VideoView.vue'),
  ...params
});

export const routeEvents = (
  name = PATHS.PATH_EVENTS,
  params = {},
  path = PATHS.PATH_EVENTS
) => ({
  path,
  name,
  props: true,
  component: () => import('../components/center/EventsView.vue'),
  ...params
});
